export const ROUTES_NAME = {
	users: '/users',
	accounts: '/accounts',
	communities: '/communities',
	settings: '/settings',
	geography: '/geography',
	actions: '/actions',
	roles: '/roles',
	channels: '/channels',
	thematics: '/thematics',
	manageFrontOffice: '/manage-front-office',
	courses: '/courses',
	classroom: '/classroom',
	translation: '/translation',
	statistics: '/statistics',
	storybook: '/storybook',
	arborescence: '/arborescence',
	contentLocation: '/contentLocation',
	login: '/login',
	notFound: '/notFound',
	payment: '/payment',
	selectEntity: '/select-entity'
};
