import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import routers from './routes';
import Spinner from './components/general/Loading/Spinner';

const queryClient = new QueryClient({
	defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

function App() {
	const routing = useRoutes(routers);

	return (
		<React.Suspense
			fallback={
				<div className="w-screen h-screen flex justify-center items-center">
					<Spinner width={50} height={50} />
				</div>
			}
		>
			<QueryClientProvider client={queryClient}>{routing}</QueryClientProvider>
			<ToastContainer closeOnClick={true} />
		</React.Suspense>
	);
}

export default App;
