import { getPath as _ } from '@/helpers/getPath';
import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ROUTES_NAME } from './routesName';
/* -------------------------------------------------------------------------- */
/*                                 MAIN LAYOUT                                 */
/* -------------------------------------------------------------------------- */
const MainLayout = React.lazy(() => import('@/layouts/Main') as any);
/* -------------------------------------------------------------------------- */
/*                                    USERS                                   */
/* -------------------------------------------------------------------------- */
const UsersPage = React.lazy(() => import('@/pages/Users') as any);
const AccountTab = React.lazy(() => import('@/pages/Users/Accounts'));
const CommunitiesTab = React.lazy(() => import('@/pages/Users/Communities'));
const CommunitiesActionsTab = React.lazy(() => import('@/pages/Users/Communities/Actions'));
const AccountActionsTab = React.lazy(() => import('@/pages/Users/Accounts/Actions'));

/* -------------------------------------------------------------------------- */
/*                                  SETTINGS                                  */
/* -------------------------------------------------------------------------- */
const SettingsPage = React.lazy(() => import('@/pages/Settings') as any);
const GeographyTab = React.lazy(() => import('@/pages/Settings/Geography'));
const GeographyActionsTab = React.lazy(() => import('@/pages/Settings/Geography/Actions'));
const RolesTab = React.lazy(() => import('@/pages/Settings/Roles'));
const RolesActionsTab = React.lazy(() => import('@/pages/Settings/Roles/Actions') as any);
const ChannelsTab = React.lazy(() => import('@/pages/Settings/Channels'));
const ChannelsActionsTab = React.lazy(() => import('@/pages/Settings/Channels/Actions') as any);
const ThematicsTab = React.lazy(() => import('@/pages/Settings/Thematics'));
const ThematicsActionsTab = React.lazy(() => import('@/pages/Settings/Thematics/Actions') as any);

/* -------------------------------------------------------------------------- */
/*                             MANAGE FRONT OFFICE                            */
/* -------------------------------------------------------------------------- */
const ManageFrontOfficePage = React.lazy(() => import('@/pages/ManageFrontOffice') as any);
const Arborescence = React.lazy(() => import('@/pages/ManageFrontOffice/Arborescence') as any);
const ActionsArborescenceTab = React.lazy(
	() => import('@/pages/ManageFrontOffice/Arborescence/Actions') as any
);
const ContentLocationTab = React.lazy(
	() => import('@/pages/ManageFrontOffice/ContentLocation') as any
);
/* -------------------------------------------------------------------------- */
/*                                   COURSES                                  */
/* -------------------------------------------------------------------------- */
const CoursesPage = React.lazy(() => import('@/pages/Courses') as any);
const CoursesActionsTab = React.lazy(() => import('@/pages/Courses/Actions') as any);
const CoursePaymentTab = React.lazy(() => import('@/pages/Courses/components/Form/Payment') as any)
/* -------------------------------------------------------------------------- */
/*                                  CLASSROOM                                 */
/* -------------------------------------------------------------------------- */
const ClassroomPage = React.lazy(() => import('@/pages/Classroom') as any);
const ClassroomActionsPage = React.lazy(() => import('@/pages/Classroom/Actions') as any);

/* -------------------------------------------------------------------------- */
/*                                 STATISTICS                                 */
/* -------------------------------------------------------------------------- */
const StatisticsPage = React.lazy(() => import('@/pages/Statistics') as any);

const LoginPage = React.lazy(() => import('@/pages/Login') as any);
const NotFoundPage = React.lazy(() => import('@/pages/404') as any);
const SelectEntityPage = React.lazy(() => import('@/pages/SelectEntity') as any);
// Docs: https://reactrouter.com/docs/en/v6/api#useroutes

const {
	users,
	accounts,
	communities,
	courses,
	settings,
	manageFrontOffice,
	statistics,
	storybook,
	geography,
	channels,
	roles,
	thematics,
	actions,
	arborescence,
	contentLocation,
	login,
	classroom,
	notFound,
	payment,
	selectEntity,
} = ROUTES_NAME;

const routers: RouteObject[] = [
	{
		path: '/',
		element: <MainLayout />,
		children: [
			/* -------------------------------------------------------------------------- */
			/*                                    USERS                                   */
			/* -------------------------------------------------------------------------- */
			{ index: true, element: <Navigate to={_(users)} replace /> },
			{
				path: _(users),
				element: <UsersPage />,
				children: [
					{ index: true, element: <Navigate to={_(users, accounts)} replace /> },
					{
						path: _(users, accounts),
						element: <AccountTab />,
					},
					{
						path: _(users, communities),
						element: <CommunitiesTab />,
					},
				],
			},
			{
				path: _(users, communities, actions),
				element: <CommunitiesActionsTab />,
			},
			{
				path: _(users, accounts, actions),
				element: <AccountActionsTab />,
			},
			/* -------------------------------------------------------------------------- */
			/*                                  SETTINGS                                  */
			/* -------------------------------------------------------------------------- */
			{
				path: _(settings),
				element: <SettingsPage />,
				children: [
					{
						index: true,
						element: <Navigate to={_(settings, geography)} replace />,
					},
					{
						path: _(settings, geography),
						element: <GeographyTab />,
					},
					{
						path: _(settings, roles),
						element: <RolesTab />,
					},
					{
						path: _(settings, channels),
						element: <ChannelsTab />,
					},
					{
						path: _(settings, thematics),
						element: <ThematicsTab />,
					},
				],
			},
			{
				path: _(settings, geography, actions),
				element: <GeographyActionsTab />,
			},
			{
				path: _(settings, roles, actions),
				element: <RolesActionsTab />,
			},
			{
				path: _(settings, channels, actions),
				element: <ChannelsActionsTab />,
			},
			{
				path: _(settings, thematics, actions),
				element: <ThematicsActionsTab />,
			},
			/* -------------------------------------------------------------------------- */
			/*                             MANAGE FRONT OFFICE                            */
			/* -------------------------------------------------------------------------- */
			{
				path: _(manageFrontOffice),
				element: <ManageFrontOfficePage />,
				children: [
					{
						index: true,
						element: <Navigate to={_(manageFrontOffice, arborescence)} replace />,
					},
					{
						path: _(manageFrontOffice, arborescence),
						element: <Arborescence />,
					},
					{
						path: _(manageFrontOffice, contentLocation),
						element: <ContentLocationTab />,
					},
				],
			},
			{
				path: _(manageFrontOffice, arborescence, actions),
				element: <ActionsArborescenceTab />,
			},
			/* -------------------------------------------------------------------------- */
			/*                                   COURSES                                  */
			/* -------------------------------------------------------------------------- */
			{
				path: _(courses),
				element: <CoursesPage />,
			},
			{
				path: _(courses, actions),
				element: <CoursesActionsTab />,
			},
			{
				path: _(courses, payment),
				element: <CoursePaymentTab />,
			},

			/* -------------------------------------------------------------------------- */
			/*                                 CLASSROOMS                                 */
			/* -------------------------------------------------------------------------- */
			{
				path: _(classroom),
				element: <ClassroomPage />,
			},
			{
				path: _(classroom, actions),
				element: <ClassroomActionsPage />,
			},
			/* -------------------------------------------------------------------------- */
			/*                                 STATISTICS                                 */
			/* -------------------------------------------------------------------------- */
			{
				path: _(statistics),
				element: <StatisticsPage />,
			},
			/* -------------------------------------------------------------------------- */
			/*                                OTHER ROUTES                                */
			/* -------------------------------------------------------------------------- */
			{
				path: _(storybook),
			},
			{
				path: '*',
				element: <Navigate to={_(notFound)} replace />,
			},
		],
	},
	{
		path: _(login),
		element: <LoginPage />,
	},
	{
		path: _(notFound),
		element: <NotFoundPage />,
	},
	{
		path: _(selectEntity),
		element: <SelectEntityPage />,
	},
];

export default routers;
